var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: {
                padding: "5px 0px",
                "text-transform": "uppercase",
              },
            },
            [_vm._v(_vm._s(_vm.pageheading))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading ? _c("div", [_c("Loading")], 1) : _vm._e(),
        _c(
          "div",
          {
            class: {
              visible: _vm.isLoading && _vm.emailTemplateItems.length === 0,
            },
            staticStyle: { overflow: "hidden" },
            attrs: { id: "body-content-area" },
          },
          [
            _c(
              "div",
              {
                staticStyle: { overflow: "hidden" },
                attrs: { id: "pagelayout" },
              },
              [
                _c(
                  "div",
                  [
                    !_vm.isLoading
                      ? _c(
                          "div",
                          {
                            staticClass: "bottom-bar",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-end",
                            },
                            attrs: { id: "buttongroup" },
                          },
                          [
                            _c("div", { staticClass: "level" }, [
                              _c("div", { staticClass: "level-left" }, [
                                _c("div", { staticClass: "field is-grouped" }, [
                                  _c("div", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        class: [
                                          "button is-accent",
                                          _vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["EMAIL_TEMPLATES"],
                                            2
                                          )
                                            ? ""
                                            : "disabled",
                                        ],
                                      },
                                      [
                                        _vm._m(0),
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "has-text-white",
                                                attrs: {
                                                  to: {
                                                    name: _vm.$hasPermissions(
                                                      _vm.clientSession,
                                                      ["EMAIL_TEMPLATES"],
                                                      2
                                                    )
                                                      ? "NewEmailTemplate"
                                                      : null,
                                                    params: {},
                                                  },
                                                },
                                              },
                                              [_vm._v("Add Template")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        class: [
                                          "button is-dark",
                                          _vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["EMAIL_TEMPLATES"],
                                            2
                                          )
                                            ? ""
                                            : "disabled",
                                        ],
                                        attrs: {
                                          disabled:
                                            !_vm.hasSelection ||
                                            !_vm.$hasPermissions(
                                              _vm.clientSession,
                                              ["EMAIL_TEMPLATES"]
                                            ),
                                        },
                                        on: { click: _vm.confirmDelete },
                                      },
                                      [
                                        _vm._m(1),
                                        _c("span", [
                                          _vm._v(
                                            "Delete Template" +
                                              _vm._s(
                                                _vm.hasPluralSelection
                                                  ? "s"
                                                  : ""
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("Grid", {
                      style: {
                        maxHeight: _vm.maxHeight + "px",
                        height: _vm.maxHeight + "px",
                        marginTop: "1rem",
                      },
                      attrs: {
                        filter: _vm.filter,
                        "data-items": _vm.emailTemplateItems,
                        "selected-field": _vm.selectedField,
                        sortable: true,
                        sort: _vm.sort,
                        filterable: false,
                        pageable: Object.assign({}, _vm.pageable, {
                          pageSizes: _vm.pageSizes,
                        }),
                        "page-size": _vm.pageSize,
                        skip: _vm.skip,
                        take: _vm.take,
                        total: _vm.totalRecords,
                        columns: _vm.columns,
                      },
                      on: {
                        selectionchange: _vm.onSelectionChange,
                        headerselectionchange: _vm.onHeaderSelectionChange,
                        filterchange: _vm.filterChange,
                        sortchange: _vm.sortChangeHandler,
                        pagechange: _vm.pageChangeHandler,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "editTemplate",
                          fn: function (ref) {
                            var props = ref.props
                            return [
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "bcc-color",
                                      attrs: {
                                        to: {
                                          name: "EditEmailTemplate",
                                          params: {
                                            id: "" + props.dataItem.id,
                                            model:
                                              "" +
                                              JSON.stringify(props.dataItem),
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(props.dataItem.name) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm.showDeleteModal
                  ? _c(
                      "div",
                      {
                        staticClass: "modal is-active",
                        attrs: { id: "delete-modal" },
                      },
                      [
                        _c("div", {
                          staticClass: "modal-background",
                          on: {
                            click: function ($event) {
                              _vm.showDeleteModal = false
                            },
                          },
                        }),
                        _c("div", { staticClass: "modal-card" }, [
                          _c(
                            "header",
                            { staticClass: "modal-card-head has-bg-danger" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-card-title has-bg-danger",
                                },
                                [_vm._v("Confirm Delete")]
                              ),
                              _c("a", {
                                staticClass: "delete",
                                attrs: { "aria-label": "close" },
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("section", { staticClass: "modal-card-body" }, [
                            _vm._m(2),
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    { name: "focus", rawName: "v-focus" },
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.confirmationText,
                                      expression: "confirmationText",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "type `delete` and then click confirm",
                                  },
                                  domProps: { value: _vm.confirmationText },
                                  on: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      return _vm.deleteIfConfirmed.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.confirmationText =
                                        $event.target.value.trim()
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("footer", { staticClass: "modal-card-foot" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-danger",
                                attrs: {
                                  disabled: _vm.confirmationText !== "delete",
                                },
                                on: { click: _vm.deleteIfConfirmed },
                              },
                              [_vm._m(3), _c("span", [_vm._v("Confirm")])]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "button",
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _vm._v(' Type "'),
      _c("b", [_vm._v("delete")]),
      _vm._v('" to confirm that you want to delete one or more items. '),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }